<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 16:32:36
-->
<script>
import TablePage from '@/found/components/table_page';
// import Modal from '@/found/components/modal';
// import configs from './data';
import Form from '../form';
import SelectSearch from './components/select_search';

export default {
  extends: TablePage,
  components: {
    // Modal,
    SelectSearch,
    Form,
  },
  data() {
    return {
      requestUrl: '',
      // configs: {
      //   formConfig: {
      //     item: [
      //       {
      //         type: 'customSearch',
      //         title: '自定义',
      //         field: 'custom',
      //       },
      //     ],
      //   },
      // },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  created() {
    this.getConfigList('dms-tartget-table');
  },
  methods: {
    // beforeSetSearch(val) {
    //   const data = val;
    //   if (val.field === 'cusCode') {
    //     data.typeName = 'SelectSearch';
    //   }
    //   return true;
    // },
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }

      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => `${row.updateDate ? row.updateDate : ''} ${row.updateDateSecond ? row.updateDateSecond : ''}`;
      }
      return rowData;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        edit: '',
        noArea: false,
      };
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          ...val,
          ...row,
          edit: 'edit',
        };
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          ...row,
          edit: 'view',
        };
      } else {
        this.modalConfig.title = '新增';
      }
      if (val.code !== 'delete') this.openFull();
    },
  },
};
</script>
