<!--
 * @Author: yzr
 * @Date: 2020-11-27 15:10:09
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-27 15:57:56
-->
<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'Target',
  components: {
    TablePage,
  },
};
</script>
