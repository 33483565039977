<template>
    <el-select :value="value"
                style="width:100%"
                @change="getCustomData"
               placeholder="请选择">
      <el-option v-for="item in options"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
      </el-option>
    </el-select>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
    };
  },
  methods: {
    getCustomData(val) {
      this.$emit('getCustomData', val);
    },
  },
};
</script>
